import { template as template_0b27317a102f4f2e805335e07c237cda } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_0b27317a102f4f2e805335e07c237cda(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
